.slick-prev, .slick-next {
  color: rgb(95, 95, 95); /* Change this to your desired color */
  z-index: 1; /* Ensure arrows are on top */
}

.slick-prev:hover, .slick-next:hover {
  color: darkblue; /* Optional: Change color on hover */
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick'; 
  font-size: 20px; /* Adjust the size of the arrows */
  line-height: 1;
  color: rgb(95, 95, 95); /* Change the color of the arrow icon */
  opacity: 1; /* Ensure the arrows are fully visible */
}

/* Optional: Adjust the positioning of the arrows */
.slick-prev {
  left: -40px; /* Adjust position of the left arrow */
}

.slick-next {
  right: -40px; /* Adjust position of the right arrow */
}